import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import configuration from "react-global-configuration";

const NoDataFound = () => {
  const defaultImageUrl = "https://cms.privatemember.fr/images/no-data-found.svg";
  const fallbackImageUrl = "https://cms.privatemember.frnewu/assets/images/no-data-found-2.svg";

  const imageUrl = configuration.get("configData.frontend_no_data_image")
    ? configuration.get("configData.frontend_no_data_image")
    : defaultImageUrl;

  return (
    <>
      <div className="no-data-found-sec">
        <Row>
          <Col sm="12" md="12">
            <div className="no-data-found-img-sec">
              <Image
                alt="not-found"
                src={imageUrl}
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default NoDataFound;

